.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #154d24;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.social-container {
  background: #eee;
  padding: 10px 35px;
  align-items: flex-start;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
  align-items: center;
  justify-content: baseline;
}

a.social:hover {
  transform: translateY(-3px);
}

a.email {
  color: black;
  margin-right: 15px;
}

a.facebook {
  color: #4968ad;
}

a.instagram {
  color: black;
}